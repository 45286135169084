'use strict';

document.addEventListener('DOMContentLoaded', function () {
  var slideLeft = {
    distance: '100%',
    origin: 'left',
    opacity: 0,
    duration: 1000
  };
  var slideUp = {
    distance: '100%',
    origin: 'bottom',
    opacity: 0,
    duration: 1000
  };
  ScrollReveal().reveal('.main-section_jumbotron--index_info', slideUp);
  ScrollReveal().reveal('.main-section', slideLeft);

  $(".fa-check").css("display", "none");
  $(".collapse").each(function () {
    $(this).on('show.bs.collapse', function () {
      $(this).parent().find(".fa-check-circle").css("display", "none");
      $(this).parent().find(".fa-check").css("display", "inline-block");
      $(this).parent().find(".fa-check").css("margin-left", "-2px");
      $(this).parent().find(".fa-check").addClass("animated fadeIn");
      $(this).parent().find(".main-section_graphic_list_link").css("color", "#72BC70");
      $(this).parent().find(".main-section_graphic_list_link").css("font-weight", "400");
      $(this).parent().find(".main-section_graphic_list_icon").css("color", "#72BC70");
    });
    $(this).on('hide.bs.collapse', function () {
      $(this).parent().find(".fa-check-circle").css("display", "inline-block");
      $(this).parent().find(".fa-check-circle").addClass("animated fadeInLeft faster");
      $(this).parent().find(".fa-check").css("display", "none");
      $(this).parent().find(".main-section_graphic_list_link").css("font-weight", "400");
      $(this).parent().find(".main-section_graphic_list_link").css("color", "#72BC70")
      //$(this).parent().find(".main-section_graphic_list_icon").css("color","#6c757d")

      ;
    });
  });
});
